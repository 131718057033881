import * as React from "react";
import { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AlertProps } from "@mui/material/Alert";
const {SayUtterance} = require("react-say")
// import {SayUtterance} from 'react-say'
interface NotificationProps {
  message: string,
  severity?: 'error' | 'success' | 'info' | 'warning',
  open: boolean,
  close: any,
  time: number | null,
  audio: any | undefined,
  children?:React.ReactNode
  }
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref as React.RefObject<HTMLDivElement>} variant="filled" {...props} />;
});

const Notification : React.FC<NotificationProps> = (props: NotificationProps) => {

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    props.close({open:false});
  };


  

  const utterance = useMemo(
    () => new SpeechSynthesisUtterance(props.audio ? props.audio : ""),
    [props.audio]
  );
  // success error

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {props.open && props.audio && <SayUtterance utterance={utterance} />}

      <Snackbar
        open={props.open}
        autoHideDuration={props.time?props.time:null}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={(event: any) => {handleClose(event, event.reason)}} severity={props.severity?props.severity:"success"} sx={{ width: "100%" }} >
          {props.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default Notification;
