import React, { useState,useEffect } from "react";
import NavBar from "../SharedComponents/NavBarComponents/NavBar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CircleIcon from "@mui/icons-material/Circle";
import Grid from "@mui/material/Grid";
const Puller = styled(Box)(({ theme }) => ({
  position: "absolute",
  color:theme.palette.primary.main,
  top: -28,
  left: "calc(50% - 25px)",
}));

const Layout = () => {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState(window.location.hash);

  const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

  useEffect(()=>{
    setLocation(window.location.hash);
  },[location])
  
  if (!isMobile || location === "")
    return (
      <React.Fragment>
        <NavBar/>
        {/* <Breadcrumbs show={location !== ""}/> */}
      </React.Fragment>
    );

  return (
    <Grid container spacing={0}>
      {!open && (
        <Grid item xs={12}>
          <Container component={"nav" as any} align="center" sx={{position: 'fixed', padding: 0, margin: 0}}>
          <Puller>
            <IconButton
              onClick={(event) => {
                setOpen(true);
              }}
              role="circle"
            >
              <CircleIcon color="primary" sx={{ padding: 0, margin: 0, fontSize: 40}}/>
            </IconButton>
            </Puller>
            <br/>
          </Container>
        </Grid>
      )}

      {open && (
        <Grid item xs={12}>
          <Slide direction="down" in={open}>
            <div>
              <NavBar handleMobileClose={()=>setOpen(!open)}/>
            </div>
          </Slide>
        </Grid>
      )}
      {/* <Breadcrumbs show={open}/> */}
    </Grid>
  );
}
export default Layout;
