import { emitCustomEvent } from 'react-custom-events';
const storage = window.localStorage;
//{}
const setLog = (data: any | any[], logKey = "log", sizeofLogs = 100) => {
  var storeArray: any = [];
  if (!storage.getItem(logKey)) {
    storeArray.push(data);
    storage.setItem(logKey, JSON.stringify(storeArray));
  } else {
    try {
      JSON.parse(storage.getItem(logKey)!).map((itm: any) => storeArray.push(itm));
      storeArray.push(data);
      if (storeArray.length < sizeofLogs) {
        localStorage.setItem(logKey, JSON.stringify(storeArray));
      } else {
        storeArray.splice(0, 1);

        localStorage.setItem(logKey, JSON.stringify(storeArray));
      }
    } catch (e: any) {
      if (
        e.name === "QuotaExceededError" ||
        e.name === "QUOTA_EXCEEDED_ERR" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED"
      ) {
        storeArray.splice(0, 1);

        localStorage.setItem(logKey, JSON.stringify(storeArray));
      }
    }
  }
};

export const getLogs = (logKey = "log") => {
  var storeArray: any = [];
  if (localStorage?.getItem(logKey) !== "") {
    JSON.parse(localStorage.getItem(logKey)!)?.map((itm: any) => storeArray.push(itm));
  }
  return storeArray;
};

export const CreateLog = (response: any, url: string, eventId: string) => {
  let log_Object;
  if (!response) response = {};
  try {
    log_Object = {
      header: {
        isSuccess: response.status === 200,
        EventID: eventId + "  " + url,
        Error: response.status === 500,
      },
      date: new Date().toString(),
      CalledUrl: url,
    };
    setLog(log_Object);
  } catch (err) {
    console.log("Create Log", err);
  }
};


export const ScreenError = (url: string, err: any,displayError=true,timeoutError: any,eventId: string) => {
  let eventIdMessage = `Event ID: ${eventId} `;
  let error = (err && err.response && err.response.data && err.response.data.error) ? err.response.data.error : null;
  let errorMsg = error
    ? "ERROR MESSAGE : " + error
    : "ERROR MESSAGE : Non Existent Lambda/AWS API GateWay timeout exception";

  const finalErrorMessage = eventIdMessage + errorMsg;
  const log_Object = {
    header: {
      isSuccess: false,
      EventID: finalErrorMessage,
    },
    date: new Date().toString(),
    CalledUrl: url,
  };
  setLog(log_Object);

  if (err.response && (err.response.status === 403)) {
    window.location.reload();
  }

  if (displayError)
    emitCustomEvent('ErrorNotificationTriggerEvent', error);

  if (err.code === "ERR_NETWORK" && timeoutError && displayError) {
    emitCustomEvent('InfoNotificationTriggerEvent',timeoutError);
  } 
};

export const LogInitiateRequest = (url: string, eventId: string) => {
  let eventIdMessage = `Event ID: ${eventId} `;
  let msg = "MESSAGE : Call was sent"

  const finalErrorMessage = eventIdMessage + msg;
  const log_Object = {
    header: {
      isSuccess: true,
      EventID: finalErrorMessage,
    },
    date: new Date().toString(),
    CalledUrl: url,
  };
  setLog(log_Object);
}


export const addFileLog = (object: any) => {
  setLog(object, "fileLogs", 22);
  //fire event
  emitCustomEvent('importEvent');
}

export const getFileLog = (requestId: string) => {
  const logs = getLogs("fileLogs");

  for (let i = 0; i < logs.length; i++) {
    if (logs[i].requestId === requestId)
      return logs[i];
  }
  return {};
}

export const updateFileLog = (requestId: string, log: any) => {
  const logs = getLogs("fileLogs");

  for (let i = 0; i < logs.length; i++) {
    if (logs[i].requestId === requestId) {
      logs[i] = log;
      localStorage.setItem("fileLogs", JSON.stringify(logs));
      break;
    }
  }

  emitCustomEvent('importEvent');

}


export const getFileLogs = () => {
  const fileLogs = JSON.parse(localStorage.getItem("fileLogs")!);

  return fileLogs;
}






