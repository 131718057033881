import React, { useEffect } from 'react';
import Layout from './Layout';
import Swiper from '../SharedComponents/NavBarComponents/Swiper';
import { RouterLayoutProps } from '../Models/RouterLayoutProps';
import { useAuth } from '../Context/auth.context';
import Settings from '../SharedComponents/Settings/SettingsBtn';
import { getUserFromLocal } from '../Utilities/Auth/auth';

const ProtectedRouterLayout: React.FC<RouterLayoutProps> = (
    props: RouterLayoutProps
) => {
    const checkIfUserIsAvailable = async () => {
        const currentUser = await getUserFromLocal();
        if (!currentUser) {
            window.location.href = '/#/Login';
        }
    }

    useEffect(() => {
        checkIfUserIsAvailable();
    }, []);

    return (
        <>
            <Layout/>
            {props.children}
            <Swiper />
            <Settings/>
        </>
    );
};

export default ProtectedRouterLayout;
