import { CognitoUserPool, CookieStorage } from "amazon-cognito-identity-js";

const poolId = process.env.REACT_APP_USER_POOL_ID;
const clientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;


const poolData = {
  UserPoolId: poolId ? poolId : '',
  ClientId: clientId ? clientId : '',
  //Storage: new CookieStorage({domain: "localhost.com"}) // Subdomains are included
};

export default new CognitoUserPool(poolData);