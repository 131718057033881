import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Container from '@mui/material/Container';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';
import LogoutBtn from './LogoutBtn';
import { useCustomEventListener, emitCustomEvent } from 'react-custom-events';
import { SwiperComponentProps } from './Models';
import { useAuth } from '../../Context/auth.context';
import { UserAttributes } from '../../Utilities/Auth/models';
import { getUserAttributes } from '../../Utilities/Auth/auth';
const drawerBleeding = 30;

const Root = styled('div')(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'light'
            ? grey[100]
            : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const SwipeableEdgeDrawer: React.FC<SwiperComponentProps> = (
    props: SwiperComponentProps
) => {
    const { user } = useAuth();
    const { window } = props;
    const [open, setOpen] = React.useState(false);
    const [useData, setUserData] = React.useState<UserAttributes | null>(null);
    const [homeUrl, setHomeURL] = React.useState<string>(null!);

    const toggleDrawer =
        (newOpen: boolean | ((prevState: boolean) => boolean)) => () => {
            setOpen(newOpen);
        };

    // this event will be captures in app.js
    const gotoHome = () => {
        let curHomeUrl = homeUrl ? homeUrl : '/';
        emitCustomEvent('goTo', curHomeUrl);
    };

    useCustomEventListener('homeUrlEvent', (homeUrl: string) => {
        setHomeURL(homeUrl);
    });

    const fetchUserAttributes = async () => {
        if (user) {
            const { data, error } = await getUserAttributes(user);

            if (data) setUserData(data);
        }
    };

    useEffect(() => {
        fetchUserAttributes();
    }, []);

    // This is used only for the example
    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
            <Button
                variant="text"
                sx={{
                    position: 'fixed',
                    bottom: -30,
                    left: 'calc(50% - 25px)',
                    display: { xs: 'block', sm: 'none' },
                }}
                onClick={() => {
                    setOpen(!open);
                }}>
                <CircleIcon color="primary" sx={{ fontSize: 40 }} />
            </Button>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        overflow: 'visible',
                    },
                }}
            />

            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: true,
                }}>
                <StyledBox
                    sx={{
                        px: 2,
                        pb: 2,
                        height: '100%',
                        overflow: 'auto',
                    }}>
                    <Container sx={{ padding: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    component="div">
                                    Logged in as {useData?.username}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LogoutBtn />
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    fullWidth
                                    onClick={gotoHome}
                                    variant="contained">
                                    Home
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
};

export default SwipeableEdgeDrawer;
