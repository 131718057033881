import React, { createContext, useEffect, useState } from "react";
import { CognitoUser } from 'amazon-cognito-identity-js';
import { getUserFromLocal } from "../Utilities/Auth/auth";

interface Props {
    children: React.ReactNode
}

const AuthContext = createContext<{ user: CognitoUser | null; setUser: React.Dispatch<React.SetStateAction<CognitoUser | null>> }>({user:null,setUser:()=>{}});

export const AuthProvider: React.FC<Props> = ({children}) => {
    const [user,setUser] = useState<CognitoUser | null>(null);

    const fetchUser = async () => {
        const currentUser = await getUserFromLocal();
        if (currentUser) setUser(currentUser);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <AuthContext.Provider value={{user,setUser}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => React.useContext(AuthContext);