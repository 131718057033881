import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import RouterLayout from './ProtectedRouterLayout';
import PublicRouterLayout from './PublicRouterLayout';

// Pages
const Home = React.lazy(() => import('./../Pages/Home/Home'));

const UserMaintenance = React.lazy(
    () => import('../Pages/UserPages/UserMaintenance/UserMaintenance')
);

const CreateAccount = React.lazy(
    () => import('../Pages/UserPages/CrateAccount/CreateAccount')
);

const Login = React.lazy(() => import('../Pages/UserPages/Login/Login'));

const VendorView = React.lazy(() => import('../Pages/VendorView/VendorView'))

const VendorViewDetail = React.lazy(() => import('../Pages/VendorViewDetail/VendorViewDetail'))

const RoutesPaths = () => {
    return (
        <HashRouter>
            <Routes>
                {/* Auth Routes */}
                <Route
                    path="/Login"
                    element={<PublicRouterLayout children={<Login />} />}
                />
                <Route
                    path="/CreateAccount/:invitationId"
                    element={
                        <PublicRouterLayout children={<CreateAccount />} />
                    }
                />

                {/* Protected Routes */}
                <Route
                    path="/"
                    element={<RouterLayout children={<Home />} />}
                />

                <Route
                    path="/UserMaintenance"
                    element={<RouterLayout children={<UserMaintenance />} />}
                />

                <Route
                    path="/VendorView"
                    element={
                        <RouterLayout
                            children={<VendorView />}
                        />
                    }
                />
                <Route
                    path="/VendorViewDetail"
                    element={
                        <RouterLayout
                            children={<VendorViewDetail />} />
                    } />
            </Routes>
        </HashRouter>
    );
};

export default RoutesPaths;
