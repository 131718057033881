import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserSession,
    MFAOption,
    UserData,
} from 'amazon-cognito-identity-js';
import UserPool from './cognito.config';
import QRCode from 'qrcode';
import { UserAttributes } from './models';
import { userAttributesListUserObject } from './helpers';

export const createUser = (
    username: string,
    password: string,
    attribute_list: CognitoUserAttribute[]
): Promise<CognitoUser | null> => {
    return new Promise((resolve, reject) => {
        UserPool.signUp(
            username,
            password,
            attribute_list,
            [],
            (err, result) => {
                if (err) {
                    reject(err);
                    return;
                }
                const cognitoUser: CognitoUser | null = result?.user || null;
                resolve(cognitoUser);
            }
        );
    });
};

export const confirmRegistration = (
    user: CognitoUser,
    code: string
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        user.confirmRegistration(code, true, function (err, result) {
            if (err) {
                reject(err);
                return;
            }
            resolve(true);
        });
    });
};

export const resendConfirmationCode = (user: CognitoUser): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        user.resendConfirmationCode(function (err, result) {
            if (err) {
                reject(err);
                return;
            }
            resolve(true);
        });
    });
};

export const login = async (
    username: string,
    password: string,
    loginResolver: (user: CognitoUser, mfaType: string) => void
): Promise<CognitoUser | null> => {
    return new Promise((resolve, reject) => {
        var authenticationData = {
            Username: username,
            Password: password,
        };
        var authenticationDetails = new AuthenticationDetails(
            authenticationData
        );

        var userData = {
            Username: username,
            Pool: UserPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                resolve(cognitoUser);
            },

            onFailure: function (err) {
                reject(err);
            },

            selectMFAType: function () {
                cognitoUser.sendMFASelectionAnswer('SMS_MFA', this);
            },

            totpRequired: function (secretCode) {
                loginResolver(cognitoUser, secretCode);
            },

            mfaRequired: function (codeDeliveryDetails) {
                loginResolver(cognitoUser, codeDeliveryDetails);
            },
        });
    });
};

export const getUserFromSession = (): Promise<CognitoUser> => {
    return new Promise((resolve, reject) => {
        const cognitoUser = UserPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession(function (
                err: any,
                session: CognitoUserSession
            ) {
                if (err) {
                    reject(err.message);
                }

                if (session.isValid()) {
                    resolve(cognitoUser);
                } else reject('user is not valid');
            });
        } else reject('user is not valid');
    });
};

export const getUserJwtToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
        const cognitoUser = UserPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession(function (
                err: any,
                session: CognitoUserSession
            ) {
                if (err) {
                    reject(err.message);
                }

                const token = session.getAccessToken().getJwtToken();
                resolve(token);
            });
        } else reject('user is not valid');
    });
};

export const getUserMFAOptions = (
    cognitoUser: CognitoUser
): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
        if (cognitoUser) {
            cognitoUser.getUserData((err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data?.PreferredMfaSetting);
            });
        }
    });
};

export const initiateAuthentiatorMfaPreference = (
    cognitoUser: CognitoUser
): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        cognitoUser.getUserData((err, data) => {
            if (err) {
                reject(err);
                return;
            }
            if (data) {
                const { Username } = data;
                const host = window.location.hostname;
                let appName = "PR External MFA";

                if(host.includes("qa")) appName = "QA "+ appName;
                else if(host.includes("staging")) appName = "Staging "+ appName;
                else if(host.includes("local")) appName = "Dev " + appName;

                cognitoUser.associateSoftwareToken({
                    associateSecretCode: async function (secretCode) {
                        const url = `otpauth://totp/${encodeURI(
                            appName
                        )}?secret=${secretCode}&issuer=PipingRock`;

                        const dataURL = await QRCode.toDataURL(url);
                        resolve(dataURL);
                    },
                    onFailure: function (err) {
                        reject(err);
                    },
                });
            }
        });
    });
};

export const verifySoftwareToken = (
    cognitoUser: CognitoUser,
    otp: string
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        cognitoUser.verifySoftwareToken(otp, 'My TOTP device', {
            onFailure: function (err) {
                reject(err);
            },
            onSuccess: function (result) {
                var totpMfaSettings = {
                    PreferredMfa: true,
                    Enabled: true,
                };
                cognitoUser.setUserMfaPreference(
                    null,
                    totpMfaSettings,
                    function (err, result) {
                        if (err) {
                            alert(err.message || JSON.stringify(err));
                        }
                    }
                );
                resolve(true);
            },
        });
    });
};

export const enableAuthenticatorMFA = (
    cognitoUser: CognitoUser
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const totpMfaSettings = {
            PreferredMfa: true,
            Enabled: true,
        };

        cognitoUser.setUserMfaPreference(
            null,
            totpMfaSettings,
            function (err, result) {
                if (err) {
                    reject(err);
                }
                resolve(true);
            }
        );
    });
};

export const getUserAttributes = (
    cognitoUser: CognitoUser
): Promise<UserAttributes> => {
    return new Promise((resolve, reject) => {
        if (cognitoUser) {
            cognitoUser.getUserAttributes((err, data) => {
                if (err) {
                    reject(err);
                }

                if (data) {
                    const user: UserAttributes =
                        userAttributesListUserObject(data);
                    user.username = cognitoUser.getUsername();
                    resolve(user);
                }
            });
        }
    });
};

export const getUserData = (
    cognitoUser: CognitoUser
): Promise<UserData | undefined> => {
    return new Promise((resolve, reject) => {
        if (cognitoUser) {
            cognitoUser.getUserData((err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data);
            });
        }
    });
};
