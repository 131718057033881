import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense, useEffect, useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { AuthProvider, useAuth } from './components/Context/auth.context';
import { NotificationSnakBar } from './components/Models/Models';
import RoutesPaths from './components/Routes/RoutesPaths';
import Loader from './components/SharedComponents/Loaders/Loader';
import Notification from './components/SharedComponents/Notification';
import { getBackground, getTheme } from './components/Utilities/themeHandler';
import { getUserFromLocal} from './components/Utilities/Auth/auth';

export interface NotificationInput {
    message: string;
    severity: 'info' | 'error' | 'success';
}

function App() {
    //user -> null after successful login make that as UserObj
    const { setUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [snakbar, setSnakbar] = useState<NotificationSnakBar>({
        open: false,
        severity: 'success',
        message: 'Success message',
        time: 10000,
        audio: '',
    });
    const theme = getTheme();
    const background = getBackground();

    const showNotification = (input: NotificationInput) => {
        const snakbar: NotificationSnakBar = {
            open: true,
            message: String(input.message),
            severity: input.severity,
            time: input.severity === 'error' ? 5000 : 3000,
            audio: '',
        };

        setSnakbar(snakbar);
    };

    useCustomEventListener(
        'NotificationTriggerEvent',
        (data: NotificationInput) => {
            showNotification(data);
        }
    );

    useCustomEventListener('goTo', (url) => {
        const win: Window = window;
        win.location = url as string;
    });

    const fetchUser = async () => {
        setLoading(true);
        const currentUser = await getUserFromLocal();
        if (currentUser) {
            setUser(currentUser);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    if (loading) return <Loader />;

    return (
        <div className="App" style={background}>
            <Notification
                open={snakbar.open}
                close={setSnakbar}
                message={snakbar.message}
                severity={snakbar.severity}
                time={snakbar.time}
                audio={snakbar.audio ? snakbar.audio : ''}
            />
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <Suspense fallback={<Loader />}>
                        <RoutesPaths />
                    </Suspense>
                </AuthProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
