import { emitCustomEvent } from 'react-custom-events';
const notificationEvent = 'NotificationTriggerEvent';

export const showSuccessMessage = (message: string) => {
    emitCustomEvent(notificationEvent, {
        message: message,
        severity: 'success',
    });
};

export const showErrorMessage = (message: string) => {
    emitCustomEvent(notificationEvent, {
        message: message,
        severity: 'error',
    });
};

export const showInfoMessage = (message: string) => {
    emitCustomEvent(notificationEvent, {
        message: message,
        severity: 'info',
    });
};
