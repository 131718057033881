import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Checkbox, FormControlLabel, Stack, Switch } from '@mui/material';
import { getUserMFAOptions } from '../../Utilities/Auth/auth';
import { useAuth } from '../../Context/auth.context';
import { showErrorMessage } from '../../Utilities/notifications';
import MFASetup from './Components/MfaSetup';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

interface Props {
    open: boolean;
    setOpen: (arg0: boolean) => void;
}

const UserSettings: React.FC<Props> = ({ open, setOpen }) => {
    const { user } = useAuth();
    const [mfaType, setMfaType] = useState('SMS_MFA'); 

    // 
    const [openMfaScanner, setOpenMfaScanner] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const completeMFASetup = () => {
        setMfaType('SOFTWARE_TOKEN_MFA');
        setOpenMfaScanner(false);
    }
    const fetchMFASetup = async () => {
        if (user) {
            const { preferredMFA, error } = await getUserMFAOptions(user);
            if (preferredMFA) {
                setMfaType(preferredMFA);
            }

            if (error) showErrorMessage(error);
        }
    };

    const initiateTOTPAuth = (initiate: boolean) => {
        setOpenMfaScanner(initiate);
    }

    useEffect(() => {
        if(open === true)
        fetchMFASetup();
    }, [open]);

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    User Settings
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <Typography sx={{ pt: 1 }}>
                                Show Time-based One-time Password QR{' '}
                            </Typography>
                            <Button onClick={()=>initiateTOTPAuth(true)}>Show</Button>
                            {/* <Switch
                                onChange={(event) => initiateTOTPAuth(event.target.checked)}
                                checked={mfaType === 'SMS_MFA' ? false : true}
                            /> */}
                        </Stack>
                    </Stack>
                    <MFASetup open={openMfaScanner} close={completeMFASetup}/>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default UserSettings;
