import axios, { CancelTokenSource } from 'axios';
import { CreateLog, ScreenError, LogInitiateRequest } from './storageHandler';
import { v4 as uuidv4 } from 'uuid';
import { getUserJwtToken } from './Auth/auth';
const NoAuthPages = ['CreateAccount'];

let options = { withCredentials: true, headers: { Authorization: '' } };
let cancelTokenForPostRequest: CancelTokenSource | null = null;

const domains = {
    prext: process.env.REACT_APP_PREXT,
    'prext-auth': process.env.REACT_APP_PREXT_AUTH,
};

const isNoAuthPage = () => {
    for (let i = 0; i < NoAuthPages.length; i++) {
        if (window.location.hash.includes(NoAuthPages[i])) return true;
    }

    return false;
};

export const Post = async <T>(
    url: string,
    input: any,
    domain: string = 'prext',
    displayError = true,
    timeoutError: string | undefined = 'APIGW Timeout Exception'
): Promise<{ data?: T | null; error?: Error | null }> => {
    let response = {};
    let eventId = uuidv4();
    const calledUrl = domains[domain as keyof typeof domains] + url;

    const token = await getUserJwtToken();
    if (!token && !isNoAuthPage())
        window.location.reload();

    let currentOptions = JSON.parse(JSON.stringify(options));
    currentOptions.headers.eventId = eventId;
    currentOptions.headers.Authorization = token;

    let error = null;
    let responseData: T | null = null;

    LogInitiateRequest(calledUrl, eventId);
    try {
        const { data, status } = await axios.post<T>(
            calledUrl,
            input,
            currentOptions
        );
        responseData = data;
        CreateLog(response, calledUrl, eventId);
    } catch (err: any) {
        ScreenError(calledUrl, err, displayError, timeoutError, eventId);
        error = err;
    }

    return { data: responseData, error: error };
};

export const Get = async <T>(
    url: string,
    domain = 'prext',
    displayError: boolean = true,
    timeoutError: string | null = 'APIGW Timeout Exception'
): Promise<{ data?: T | null; error?: Error | null }> => {
    let response = {};
    let eventId = uuidv4();
    const calledUrl = domains[domain as keyof typeof domains] + url;

    const token = await getUserJwtToken();
    if (!token && !isNoAuthPage())
        window.location.reload();
    
    let currentOptions = JSON.parse(JSON.stringify(options));
    currentOptions.headers.eventId = eventId;
    currentOptions.headers.Authorization = token;

    let error = null;
    let responseData: T | null = null;

    // Log the initial request
    LogInitiateRequest(calledUrl, eventId);
    try {
        const { data, status } = await axios.get<T>(calledUrl, currentOptions);
        responseData = data;
        CreateLog(response, calledUrl, eventId);
    } catch (err: any) {
        ScreenError(calledUrl, err, displayError, timeoutError, eventId);
        error = err;
    }
    return { data: responseData, error: error };
};

export const PostWithCancelToken = async <T>(
    url: string,
    input: any,
    domain: string = 'prext',
    displayError = true,
    timeoutError: string | undefined = 'APIGW Timeout Exception'
): Promise<{ data?: T | null; error?: Error | null }> => {
    let response = {};
    let eventId = uuidv4();
    const calledUrl = domains[domain as keyof typeof domains] + url;

    if (cancelTokenForPostRequest !== null) {
        cancelTokenForPostRequest.cancel('REQUEST_CANCLED');
    }

    cancelTokenForPostRequest = axios.CancelToken.source();

    const token = await getUserJwtToken();
    if (!token) window.location.reload();
    let currentOptions = JSON.parse(JSON.stringify(options));
    currentOptions.headers.eventId = eventId;
    currentOptions.headers.Authorization = token;

    let error = null;
    let responseData: T | null = null;

    LogInitiateRequest(calledUrl, eventId);
    try {
        const { data, status } = await axios.post<T>(calledUrl, input, {
            ...currentOptions,
            cancelToken: cancelTokenForPostRequest.token,
        });
        responseData = data;
        CreateLog(response, calledUrl, eventId);
    } catch (err: any) {
        ScreenError(calledUrl, err, displayError, timeoutError, eventId);
        error = err;
    }

    return { data: responseData, error: error };
};

export const invokeAPI = async <T>(
    apiId: string,
    queryStringParameters: Object | null,
    body: Object | null,
    useCancleToken: boolean = false
) => {
    const url = `/Common/invokeLambda?apiId=${apiId}`;
    const input = { queryStringParameters, body };

    if (useCancleToken) return await PostWithCancelToken<T>(url, input);
    return await Post<T>(url, input);
};
