import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../Context/auth.context';
import { getUserAttributes } from '../../Utilities/Auth/auth';
import { UserAttributes } from '../../Utilities/Auth/models';
import Logscreen from '../Logscreen';
import LogoutBtn from './LogoutBtn';
import { MobileMenuProps, NavBarProps } from './Models';

const logo = require('../../../images/pr.png');

const MobileMenu: React.FC<MobileMenuProps> = (props: MobileMenuProps) => {
    const theme = useTheme();

    const display = props.open ? 'block' : 'none';
    return (
        <Slide direction="down" in={props.open}>
            <Container
                sx={{
                    backgroundColor: `${theme.palette.secondary.main}`,
                    display: { display },
                }}>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Typography variant="subtitle2" component="div">
                            Logged in as {props.user?.username}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" component="div">
                            <LogoutBtn />
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Slide>
    );
};

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
    const { user } = useAuth();
    const search = useLocation().search;
    const [mobileOpen, setOpen] = React.useState(false);
    const [userData, setUserData] = React.useState<UserAttributes | null>(null);
    const [username, setUserName] = React.useState<string | null>(null);
    const theme = useTheme();

    const fname = new URLSearchParams(search).get('fname')
        ? new URLSearchParams(search).get('fname')
        : 'Home';

    const fetchUserAttributes = async () => {
        if (user) {
            const {data} = await getUserAttributes(user);

            if(data){
            const username = data.email.split("@")[0];
            setUserName(username);
            setUserData(data);
            }
        }
    };
    useEffect(() => {
        document.title = fname ?? ''
    })
    useEffect(() => {
        fetchUserAttributes();
    }, [user]);

    return (
        <React.Fragment>
            <AppBar
                style={{ background: `${theme.palette.secondary.main}` }}
                position="sticky"
                className="navbar"
                sx={{ marginBottom: 1 }}>
                <Toolbar align="center" component={'div' as any}>
                    <Link
                        underline="none"
                        href={'/'}
                        sx={{ mr: 0, display: 'flex' }}>
                        <Avatar
                            alt="Piping Rock"
                            sx={{ width: 36, height: 36 }}
                            src={logo}
                        />
                    </Link>

                    <Typography
                        variant="h6"
                        role="title"
                        color={`${theme.palette.secondary.contrastText}`}
                        component="div"
                        sx={{ flexGrow: 1, marginLeft: { sm: 15 } }}
                        onClick={
                            props.handleMobileClose ?? props.handleMobileClose
                        }>
                        {fname}
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        component="div"
                        color={`${theme.palette.secondary.contrastText}`}
                        sx={{ display: { xs: 'none', sm: 'block' } }}>
                        Logged in as {username ? username : ''}
                    </Typography>

                    <Box sx={{ m: 1 }} />

                    <LogoutBtn position="nav" />

                    <Logscreen />

                    <IconButton
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                        aria-label="MoreHorizIcon"
                        size="small"
                        onClick={() => setOpen(!mobileOpen)}>
                        <MoreHorizIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MobileMenu
                open={mobileOpen}
                user={userData}
                languageHandler={props.languageHandler}
                handler={setOpen}
            />
        </React.Fragment>
    );
};

export default NavBar;
