import { CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import * as AuthUtils from './cognito.utils';
import { Get } from '../AxiosHandler';
import { handleUserError } from './helpers';

export const registerUser = async (
    username: string,
    email: string,
    phone: string,
    password: string,
    firstName: string,
    lastName: string,
    userType: string,
    userTypeIds: string,
    invitationId: string
): Promise<{ cognitoUser: CognitoUser | null; error: string | null }> => {
    var dataEmail = {
        Name: 'email',
        Value: email,
    };

    var dataPhoneNumber = {
        Name: 'phone_number',
        Value: phone,
    };

    var dataFName = {
        Name: 'given_name',
        Value: firstName,
    };

    var dataLName = {
        Name: 'family_name',
        Value: lastName,
    };

    var userTypeName = {
        Name: 'custom:user_type',
        Value: userType,
    };

    var userTypeIdsName = {
        Name: 'custom:user_type_ids',
        Value: userTypeIds,
    };

    var userInvitationName = {
        Name: 'custom:invitation_id',
        Value: invitationId,
    };

    var dataUpdatedAt = {
        Name: 'updated_at',
        Value: Math.floor(new Date().getTime() / 1000).toString(),
    };

    const attributeList = [
        new CognitoUserAttribute(dataEmail),
        new CognitoUserAttribute(dataPhoneNumber),
        new CognitoUserAttribute(dataFName),
        new CognitoUserAttribute(dataLName),
        new CognitoUserAttribute(userTypeName),
        new CognitoUserAttribute(userTypeIdsName),
        new CognitoUserAttribute(userInvitationName),
        new CognitoUserAttribute(dataUpdatedAt),
    ];

    let cognitoUser: CognitoUser | null = null;

    try {
        cognitoUser = await AuthUtils.createUser(
            username,
            password,
            attributeList
        );

        return { cognitoUser, error: null };
    } catch (error: any) {
        return { cognitoUser, error: error.message };
    }
};

export const confirmUserAccount = async (
    user: CognitoUser,
    code: string
): Promise<{ isAccountCreated: boolean; error: string | null }> => {
    let isAccountCreated = false;

    try {
        isAccountCreated = await AuthUtils.confirmRegistration(user, code);

        return { isAccountCreated, error: null };
    } catch (error: any) {
        return { isAccountCreated, error: error.message };
    }
};

export const resendOTP = async (
    user: CognitoUser
): Promise<{ isOtpSent: boolean; error: string | null }> => {
    let isOtpSent = false;

    try {
        isOtpSent = await AuthUtils.resendConfirmationCode(user);

        return { isOtpSent, error: null };
    } catch (error: any) {
        handleUserError(error);

        return { isOtpSent, error: error.message };
    }
};

export const login = async (
    username: string,
    password: string,
    loginResolver: (user: CognitoUser, mfaType: string) => void
): Promise<{ user: CognitoUser | null; error: string | null }> => {
    let user = null;

    try {
        const user = await AuthUtils.login(username, password, loginResolver);

        return { user, error: null };
    } catch (error: any) {
        return { user, error: error.message };
    }
};

export const getUserFromLocal = async (): Promise<CognitoUser | null> => {
    try {
        const user: CognitoUser = await AuthUtils.getUserFromSession();
        return user;
    } catch (error: any) {
        handleUserError(error);
        return null;
    }
};

export const getUserJwtToken = async (): Promise<string | null> => {
    try {
        const token: string = await AuthUtils.getUserJwtToken();
        return token;
    } catch (error: any) {
        return null;
    }
};

export const getUserMFAOptions = async (
    user: CognitoUser
): Promise<{
    preferredMFA: string | null | undefined;
    error: string | null;
}> => {
    try {
        const preferredMFA = await AuthUtils.getUserMFAOptions(user);
        return { preferredMFA: preferredMFA, error: null };
    } catch (error: any) {
        handleUserError(error);
        return { preferredMFA: null, error: error.message };
    }
};

export const initiateAuthentiatorMfaPreference = async (
    user: CognitoUser
): Promise<{ dataURL: string | null; error: string | null }> => {
    try {
        const dataURL = await AuthUtils.initiateAuthentiatorMfaPreference(user);
        return { dataURL, error: null };
    } catch (error: any) {
        handleUserError(error);
        return { dataURL: null, error: error.message };
    }
};

export const verifyTokenAndEnableAuthenticatorMFA = async (
    user: CognitoUser,
    otp: string
): Promise<{ success: boolean; error: string | null }> => {
    try {
        AuthUtils.verifySoftwareToken(user, otp);
        await AuthUtils.enableAuthenticatorMFA(user);

        return { success: true, error: null };
    } catch (error: any) {
        handleUserError(error);
        return { success: true, error: error.message };
    }
};

export const getUserAttributes = async (user: CognitoUser) => {
    try {
        //const userAttributes = await AuthUtils.getUserAttributes(user);
        const data = await AuthUtils.getUserAttributes(user);
        return { data: data, error: null };
    } catch (error: any) {
        handleUserError(error);
        return { data: null, error: error.message };
    }
};

export const isAdmin = async (): Promise<boolean> => {
    interface Res {
        success: boolean;
        message: null;
        data: null;
    }
    const url = '/Auth/isAdmin';
    const { data, error } = await Get<Res>(url, 'prext-auth');

    if (error) {
        return false;
    }

    if (data) return data.success;

    return false;
};

