import React, { useState } from "react";
import Button from "@mui/material/Button";
import { IconContext } from 'react-icons';
import { FaSignOutAlt } from 'react-icons/fa';
import { LogOutBtnProps } from "./Models";
import { useAuth } from "../../Context/auth.context";


const LogoutBtn: React.FC<LogOutBtnProps> = (props: LogOutBtnProps) => {
  const {user,setUser} = useAuth();
  const [iconColor,setIconColor] = useState("#d9d9d9");

  var display;
  if (props.position === "nav") display = { xs: "none", sm: "block" };
  const Logout = () => {
    if(user){
      user.signOut();
      setUser(null);
      window.location.href = "/#/Login";
    }
  };

  const handleMouseOver = () => {
    setIconColor("#fff");
  }

  const handleMouseOut = () => {
    setIconColor("#d9d9d9");
  }

  if (props.position !== 'nav')
  return (
      <Button
          variant="outlined"
          sx={{
              color: 'black',
              backgroundColor: 'white',
              fontWeight: 'bold',
              display: display,
          }}
          onClick={() => Logout()}>
          Logout
      </Button>
  );

return (
  <IconContext.Provider
      value={{
          color: iconColor,
          size: '1.2em',
          style:{cursor:'pointer'}
      }}
      >
      <FaSignOutAlt
          onClick={Logout}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
      />
  </IconContext.Provider>
);
}

export default LogoutBtn;
