import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { UserAttributes } from "./models";

export const userAttributesListUserObject = (array: CognitoUserAttribute[]): UserAttributes => {
    const result: any = {};

    array.forEach(item => {
        const name = item.getName();
        const value = item.getValue();
        
        if (name && value) {
            result[name] = value;
        }
    });

    return result as UserAttributes;
};

export const handleUserError = (err: any) => {
    if(err.code && (err.code === "NotAuthorizedException" || err.code === "UserNotFoundException") ){
        clearLocalStorageForUser();
        window.location.reload();
    }
}

const clearLocalStorageForUser = () => {
    // CognitoIdentityServiceProvider Key regx
    const regexPattern = /^CognitoIdentityServiceProvider\..*/;
    // Get all keys from local storage
    const keys = Object.keys(localStorage);
  
    // Iterate through the keys and remove the ones matching the regex pattern
    keys.forEach((key) => {
      if (key.match(regexPattern)) {
        localStorage.removeItem(key);
      }
    });
  };
  