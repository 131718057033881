import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import Slide, { SlideProps } from "@mui/material/Slide";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { Container } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { getLogs } from "../Utilities/storageHandler";
import { TransitionProps } from "@mui/material/transitions";
import { NotificationRowProps } from "../Models/Models";

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as SlideProps)}/>;
});



const NotificationRow: React.FC<NotificationRowProps> = (props: NotificationRowProps) => {
  const [open, setOpen] = React.useState(false);
  const data = props.data;
  const status = data.header && data.header.isSuccess
    ? "success"
    : (data.header && data.header.Error)
    ? "error"
    : "warning";

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    handleCopy();
    setOpen(true);
  };

  const handleCopy = () => {
    let text="";
    text = text + "SUCCESS : " + data.header?.isSuccess + "\n";
    text = text + "EventID : " + data.header?.EventID + "\n";
    text = text + "URL : " + data.CalledUrl + "\n";
    text = text + "DATE : " + data.date + "\n";
    text = `${text}\n\n`  //text + "\n" + "\n";
    navigator.clipboard.writeText(text);
  };
  return (
    <Alert
      severity={status}
      data-testid={data.header?.EventID}
      sx={{ overflow: "auto" }}
      hidden={false}
      action={
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied to your clipboard"
            >
              <Button color="inherit" onClick={handleTooltipOpen} size="small">
                <ContentCopyIcon />
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      }
    >
      <AlertTitle data-testid={data.date}>{data.CalledUrl}</AlertTitle>
      {data.header?.EventID}
      <br />
      {String(data.date)}
    </Alert>
  );
};

const Notifications = (props: {data: any}) => {
  return (
    <Stack
      sx={{
        paddingLeft: { sm: 3, xs: 1 },
        paddingTop: 1,
        width: "100%"
      }}
      role="logs"
      spacing={2}
    >
      {props.data.map((log: {date: any, CalledUrl: string}) => {
        return (
          <NotificationRow
            key={log.date + log.CalledUrl}
            status="error"
            data={log}
          />
        );
      })}
    </Stack>
  );
};

export default function Logscreen() {
  const [open, setOpen] = React.useState(false);
  const [tipOpen, setTipOpen] = React.useState(false);
  const version = process.env.REACT_APP_RLS_VERSION;
  const theme = useTheme();
  let error = false;
  let Logs = [];
  try {
    Logs = getLogs();
    Logs.reverse();
  } catch (err) {
    error = true;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getLogIds = (Logs: any) => {
    let successLogs = "";
    let failedLogs = "";

    for (let i = 0; i < Logs.length; i++) {
      if (Logs[i].header.isSuccess) successLogs = successLogs + ", " + (i + 1);
      else failedLogs = failedLogs + ", " + (i + 1);
    }

    return [successLogs, failedLogs];
  };
  const copyHandler = () => {
    let Logs = getLogs();
    Logs.reverse();
    let text = "";
    if (Logs.length === 0) {
      text = "No content to copy";
    } else {
      text = "";
      const logIds = getLogIds(Logs);

      text = `${text} + "SUCCESS LOGS : " + ${logIds[0]}\n\n`;
      text = `${text} + "FAILED LOGS : " + ${logIds[1]}\n\n\n`;
      text =
        text +
        "***************************************LOGS***************************************" +
        "\n";
      for (let i = 0; i < Logs.length; i++) {
        text = text + "LOG NUMBER : " + (i + 1).toString() + "\n";
        text = text + "SUCCESS : " + Logs[i].header.isSuccess + "\n";
        text = text + "EventID : " + Logs[i].header.EventID + "\n";
        text = text + "URL : " + Logs[i].CalledUrl + "\n";
        text = text + "DATE : " + Logs[i].date + "\n";
        text = `${text}\n\n`  //text + "\n" + "\n";
      }
    }
    navigator.clipboard.writeText(text);
  };

  const handleTooltipOpen = () => {
    copyHandler();
    setTipOpen(true);
  };

  const handleTooltipClose = () => {
    setTipOpen(false);
  };

  return (
    <div>
      <IconButton
        color="primary"
        onClick={handleClickOpen}
        aria-label="Log button"
        component="span"
      >
        <ReportGmailerrorredIcon sx={{ color: "black" }} />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{ background: `${theme.palette.secondary.main}` }} sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon  sx={{ color: theme.palette.secondary.contrastText}} />
            </IconButton>
            <Typography  color={`${theme.palette.secondary.contrastText}`} sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Log Reports {version}
            </Typography>
            
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={tipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied to your clipboard"
                >
                  <Button
                    onClick={handleTooltipOpen}
                    size="small"
                  >
                    <ContentCopyIcon sx={{ color: theme.palette.secondary.contrastText}}/>
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Toolbar>
        </AppBar>

        {error && (
          <Container align="center" component={"nav" as any}>
            {" "}
            <h4>some thing went wrong</h4>
          </Container>
        )}
        <Notifications data={Logs} />
      </Dialog>
    </div>
  );
}
