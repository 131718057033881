import { useRef, useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import Draggable from 'react-draggable';
import React from 'react';
import UserSettings from './UserSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const Settings = () => {
    const nodeRef = useRef(null);
    const [openUserSettings, setOpenUserSettings] = useState(false);

    return (
        <>
            <Draggable nodeRef={nodeRef}>
                <SpeedDial
                    onClick={() => setOpenUserSettings(true)}
                    ref={nodeRef}
                    ariaLabel="Settings"
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                    }}
                    icon={<ManageAccountsIcon />}></SpeedDial>
            </Draggable>
            <UserSettings
                open={openUserSettings}
                setOpen={setOpenUserSettings}
            />
        </>
    );
};

export default Settings;
