import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { CardMedia, Stack, TextField } from '@mui/material';
import { initiateAuthentiatorMfaPreference, verifyTokenAndEnableAuthenticatorMFA } from '../../../Utilities/Auth/auth';
import { showErrorMessage, showSuccessMessage } from '../../../Utilities/notifications';
import { useAuth } from '../../../Context/auth.context';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

interface Props {
    open: boolean;
    close: () => void;
}

const MFASetup: React.FC<Props> = ({ open, close }) => {
    const { user } = useAuth();
    const [dataURL, setDataURL] = useState<string | null>(null);
    const [totp, setToTP] = useState('');

    const handleClose = () => {
        close();
    };

    const initiateAuthenticatorMFA = async () => {
        if (user) {

            const { dataURL, error } = await initiateAuthentiatorMfaPreference(
                user
            );
            if (dataURL) {
                setDataURL(dataURL);
            }

            if (error) showErrorMessage(error);
        }
    };

    
    const verifyOTP = async () => {
        if (user) {
            if (!totp) showErrorMessage('TOTP should not be empty');

            const { success, error } =
                await verifyTokenAndEnableAuthenticatorMFA(user, totp);
            if (error) {
                showErrorMessage(error);
                return 0;
            }

            showSuccessMessage('MFA was updated to TOTP');
            setDataURL(null);
            handleClose();
        }
    };

    useEffect(() => {
        if (open === true) initiateAuthenticatorMFA();
    }, [open]);

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    User Settings
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {
                        dataURL && <>
                         <Typography textAlign={'center'}>
                                        Scan the below QR Code in any
                                        authenticator app, this will link your
                                        account with Authenticator app
                                    </Typography>
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        height="250"
                                        component="img"
                                        image={dataURL}
                                        alt="QR Code"
                                    />
                                    <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                                        <TextField
                                            id="outlined-basic"
                                            required
                                            label="TOTP"
                                            size="small"
                                            variant="outlined"
                                            value={totp}
                                            onChange={(event) =>
                                                setToTP(event.target.value)
                                            }
                                        />
                                        <Button
                                            
                                            variant="contained"
                                            onClick={verifyOTP}>
                                            Verify
                                        </Button>
                                    </Stack>
                            
                        </>
                    }
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default MFASetup;
