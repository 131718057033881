import React, { useEffect } from 'react';
import { useAuth } from '../Context/auth.context';
import { getUserFromLocal } from '../Utilities/Auth/auth';

interface Props {
    children: React.ReactNode;
}

const PublicRouterLayout: React.FC<Props> = ({ children }) => {
    const checkIfUserIsAvailable = async () => {
        const currentUser = await getUserFromLocal();
        if (currentUser) {
            window.location.href = "/";
        }
    };

    useEffect(() => {
       checkIfUserIsAvailable();
    }, []);

    return <>{children}</>;
};

export default PublicRouterLayout;
